<template>
  <div class="main-content">
    <PromoDesktop>
      <template v-if="abFormSelectorVariant === '2'" #controls>
        <VButtonLinkMTS
          :href="'/user/entrance'"
          class="--xl --violet-gradient promo__controls_btn"
        >
          Попробовать бесплатно
        </VButtonLinkMTS>
        <VButtonLinkMTS
          :href="'/user/demo'"
          class="--xl --grey promo__controls_btn"
        >
          Оставить заявку
        </VButtonLinkMTS>
      </template>
      <template v-else-if="abFormSelectorVariant === '3'" #controls>
        <VButtonLinkMTS
          :href="'/user/entrance'"
          class="--xl --violet-gradient promo__controls_btn"
        >
          Попробовать бесплатно
        </VButtonLinkMTS>
        <VButtonLinkMTS
          :href="'/user/demo'"
          class="--xl --grey promo__controls_btn"
        >
          Получить консультацию
        </VButtonLinkMTS>
      </template>
      <template v-else #controls>
        <VButtonLinkMTS
          :href="btnPromoTry.href"
          class="--xl --violet-gradient promo__controls_btn"
        >
          Войти или зарегистрироваться
        </VButtonLinkMTS>
      </template>
    </PromoDesktop>

    <div class="container">
      <ProductsDesktop />

      <ClientsSliderDesktop :substrate-color="'--grey'" />

      <LazyHydrate when-visible>
        <LazyServicesDesktop />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <LazyPremiseDesktop />
      </LazyHydrate>
      <LazyHydrate when-visible>
        <LazyBlockWithNumbersDesktop> </LazyBlockWithNumbersDesktop>
      </LazyHydrate>
    </div>

    <div class="substrate --white-bg">
      <div class="container">
        <LazyHydrate when-visible>
          <LazyExperienceDesktop />
        </LazyHydrate>
        <LazyHydrate when-visible>
          <LazyTaskDesktop />
        </LazyHydrate>
        <LazyHydrate when-visible>
          <LazyReviewsDesktop
            :title="'Что о\u00A0нас говорят клиенты'"
            :color-card="'--grey'"
            :reviews-list="ReviewsListDesktop"
          />
        </LazyHydrate>
      </div>
    </div>

    <div class="container">
      <LazyHydrate when-visible>
        <LazyAdvantagesDesktop />
      </LazyHydrate>
      <LazyHydrate when-visible>
        <LazyFaqDesktop :title="true" />
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import EventBus from '@/mixins/EventBus'
import MainMixin from '~/components/Page/Main/MainMixin'
import ReviewsMixin from '~/components/Desktop/Main/mixins/ReviewsMixin'
import PromoDesktop from '~/components/Desktop/Main/PromoDesktop.vue'
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import LazyServicesDesktop from '~/components/Desktop/Main/Lazy/LazyServicesDesktop.vue'
import LazyPremiseDesktop from '~/components/Desktop/Main/Lazy/LazyPremiseDesktop.vue'
import LazyTaskDesktop from '~/components/Desktop/Main/Lazy/LazyTaskDesktop.vue'
import LazyExperienceDesktop from '~/components/Desktop/Main/Lazy/LazyExperienceDesktop.vue'
import LazyReviewsDesktop from '~/components/Desktop/Common/Lazy/LazyReviewsDesktop.vue'
import LazyFaqDesktop from '~/components/Desktop/Common/Lazy/LazyFaqDesktop.vue'
import LazyBlockWithNumbersDesktop from '~/components/Desktop/Main/Lazy/LazyBlockWithNumbersDesktop.vue'
import LazyAdvantagesDesktop from '~/components/Desktop/Main/Lazy/LazyAdvantagesDesktop.vue'
import ClientsSliderDesktop from '~/components/Common/ClientsSlider.vue'
import ProductsDesktop from '~/components/Desktop/Main/ProductsDesktop.vue'

export default {
  name: 'MainPageDesktop',
  components: {
    ClientsSliderDesktop,
    LazyPremiseDesktop,
    LazyBlockWithNumbersDesktop,
    LazyFaqDesktop,
    LazyReviewsDesktop,
    LazyExperienceDesktop,
    LazyTaskDesktop,
    LazyServicesDesktop,
    LazyAdvantagesDesktop,
    VButtonLinkMTS,
    PromoDesktop,
    LazyHydrate,
    ProductsDesktop,
  },
  mixins: [EventBus, MainMixin, ReviewsMixin],
}
</script>

<style lang="scss" scoped>
.main-content {
  font-family: 'MTS Compact', 'Arial', sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 28px;
  color: #1d2023;

  .container {
    max-width: 1284px;
    padding-left: 10px;
    padding-right: 10px;

    @include screen-down('lg') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .substrate {
    &.--white-bg {
      border-radius: 24px;
      background: $color-white;
    }
  }
}

::v-deep {
  .page-section {
    &__title {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
      text-align: center;
      padding-bottom: 32px;
    }
  }

  .clients {
    padding: 64px 0;

    @include screen-down('lg') {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__ticker {
      width: auto;
    }
  }

  .reviews {
    .swiper-slide {
      max-width: 616px;
    }

    .reviews__card {
      min-height: 354px;
      max-height: 354px;
      color: $color-dark-2;
    }
  }
}
</style>
